// additional icon on node (outside of anchor)
(function ($, undefined) {
	"use strict";
	var configIt = $('<a href="#" class="configit hash-url"><i class="icon settings"></i>Configure it</a>');

	$.jstree.defaults.configit = $.noop;
	$.jstree.plugins.configit = function (options, parent) {
		this.bind = function () {
			parent.bind.call(this);
		};
		this.teardown = function () {
			if(this.settings.configit) {
				this.element.find(".jstree-configit").remove();
			}
			parent.teardown.call(this);
		};
		this.redraw_node = function(obj, deep, callback) {
			obj = parent.redraw_node.call(this, obj, deep, callback);
			var $obj = $(obj);
			if(obj && this.is_leaf(obj)) {
				var tmp = configIt.clone();
				var $a = $obj.find('a').eq(0);
				var hide = $a.data('hideconfigit');
				if(!hide) {
					tmp.attr({
						href: $a.data('url'),
						id: $a.data('id')
					});
					$obj.append(tmp);
				}
			}
			return obj;
		};
	};
})(jQuery);