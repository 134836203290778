(function() {
	/**
	 * @exports EAT.controllers.home
	 * @classdesc An example class.
	 * @requires HBS
	 */
	var module = {};

	/**
	 * A static function
	 * @static
	 */
	module.isMobile = function() {
		var retVal = false;
		if ('matchMedia' in window) {
			retVal = window.matchMedia('(max-width: 767px)').matches;
		} else {
			retVal = $(window).width() < 768
		}

		return retVal;
	};

	/**
	 * A static function
	 * @static
	 */
	module.mediaQuery = {
		xs: function(){
			return window.matchMedia('(min-width: 0px) and (max-width: 767px)').matches;
		},
		sm: function(){
			return window.matchMedia('(min-width: 768px) and (max-width: 1023px)').matches;
		},
		md: function(){
			return window.matchMedia('(min-width: 1024px) and (max-width: 1139px)').matches;
		},
		lg: function(){
			return window.matchMedia('(min-width: 1140px)').matches;
		}
	};


	/* Setup Switches */
	module.setupSwitch = function(){
		$.fn.bootstrapSwitch.defaults.size = 'large';
		$.fn.bootstrapSwitch.defaults.onSwitchChange = function(){
			$(this).trigger('change');
		};

		$('.switch-box').bootstrapSwitch();

		// Enable clicks on .switch-label
		$('.switch-label').on('click', function(){
			$(this).parents('label').trigger('click');
		});
	};

	module.isAndroidBrowser = function(){
		var nua = navigator.userAgent;
		var isAndroid = (nua.indexOf('Mozilla/5.0') > -1 && nua.indexOf('Android') > -1 && nua.indexOf('AppleWebKit') > -1 && nua.indexOf('Chrome') === -1);
		if (isAndroid) {
			return isAndroid;
		}
	};

	module.setSwitches = function () {
		$('.switch-box').each(function () {
			if ($(this).is(':checked')) {
				$(this).bootstrapSwitch('toggleState', true);
			}
		});
	};
	/* Switch Functions End */

	/**
	 * A static function
	 * @static
	 */
	module.isWide = function() {
		var retVal = false;
		if ('matchMedia' in window) {
			retVal = window.matchMedia('(min-width: 992px)').matches;
		} else {
			retVal = $(window).width() >= 992
		}

		return retVal;
	};

	HBS.namespace('EAT.utils', module);
}());
